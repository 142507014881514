.WebsitePopup {
    position: fixed;
    display: block;
    color: black;
    width: 483px;
    height: 726px;
    /* background: url("https://reality-reach-assets.storage.googleapis.com/ar-card/images/bg/background_1.jpg"); */
    background-color: rgba(0, 0, 0, 0);
    top: 0vh;
    right: 0%;
    opacity: 0.9;
    transition: opacity 1s ease, background 1s ease;
    z-index: 10;
}

.WebsiteTransitionIn {
    width: 483px;
    height: 726px;
    position: fixed;
    display: block;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
    opacity: 1;
    /* bottom: 0; */
}