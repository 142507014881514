#example-scanning-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: transparent;
    z-index: 2;
}

@media (min-aspect-ratio: 1/1) {
    #example-scanning-overlay .inner {
        width: 80vw;
        height: 30vh;
    }
}

@media (max-aspect-ratio: 1/1) {
    #example-scanning-overlay .inner {
        width: 80vw;
        height: 20vh;
    }
}

#example-scanning-overlay .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;


    /*     background:
        linear-gradient(to right, white 10px, transparent 10px) 0 0,
        linear-gradient(to right, white 10px, transparent 10px) 0 100%,
        linear-gradient(to left, white 10px, transparent 10px) 100% 0,
        linear-gradient(to left, white 10px, transparent 10px) 100% 100%, 
        linear-gradient(to bottom, white 10px, transparent 10px) 0 0,
        linear-gradient(to bottom, white 10px, transparent 10px) 100% 0,
        linear-gradient(to top, white 10px, transparent 10px) 0 100%,
        linear-gradient(to top, white 10px, transparent 10px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 40px 20px; */
}

#example-scanning-overlay .vision {
    width: 80vw;
    top: 5vh;
    opacity: 1;
    display: flex;
    align-items: center;
    position: absolute;
}

#example-scanning-overlay .instructions {
    width: 100vw;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: fixed;
    opacity: 1;
    z-index: 5
}

#example-scanning-overlay .instructions img {
    width: 50vw;
    top: 20vh;
    position: absolute;
}

#example-scanning-overlay .instructions p {
    color:white;
    top: 30vh;
    position: absolute;
}

#example-scanning-overlay .instructions .visionstatement{
    width: 80vw;
    color:white;
    top:-60vh;
    position: absolute;
}

#example-scanning-overlay.hidden {
    display: none;
}

#example-scanning-overlay .centralImages img {
    opacity: 0.6;
    width: 80%;
    align-self: center;
}

#example-scanning-overlay .inner .scanline {
    position: absolute;
    width: 100%;
    height: 10px;
    /*  background: white; */
    animation: move 2s linear infinite;
}

@keyframes move {

    0%,
    100% {
        top: 0%
    }

    50% {
        top: calc(100% - 10px)
    }
}