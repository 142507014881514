@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&family=Changa&family=Sarabun:wght@300&display=swap" rel="stylesheet');

.animated {
    /*    position: 'fixed'; */
    /*     top: '90vh';
    right: '50vh';
    text-align: center; */
    font-size: 18;
    width: 100%;
    font-family: 'Changa';
    justify-content: 'center';
    align-items: 'center';
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 0.8;
    }

    85% {
        opacity: 0.8;
    }

    100% {
        opacity: 0;
    }
}

.fadeInOut {
    -webkit-animation-name: fadeOut;
    animation: fadeInOut;
    align-self: center;
    position: fixed;
    /*    top: 90%; */
    /* left: calc(50% - 15px); */
    /*    text-align: center; */
    font-size: 18;
    width: 100%;
    font-family: 'Changa';
    justify-content: 'center';
    align-items: 'center';
    -webkit-animation-duration: 4.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.centerText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18;
    width: 100%;
    font-family: 'Changa';
    justify-content: 'center';
    align-items: 'center';
}