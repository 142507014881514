#event-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
/*     background: black;
    color: black;
    background-color: black; */
    z-index: 10;
}
