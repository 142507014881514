@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&family=Sarabun:wght@300&display=swap" rel="stylesheet');

.App {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Oxygen, Ubuntu, Raleway,
    Cantarell, Plus Jakarta Sans,
    Fira Sans, Sarabun, Droid Sans, Helvetica Neue, sans-serif;
}

.container {
  margin:
    /* 20px */
    auto;
  font-family: -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Oxygen, Ubuntu, Raleway,
    Cantarell, Plus Jakarta Sans,
    Fira Sans, Sarabun, Droid Sans, Helvetica Neue, sans-serif;
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}